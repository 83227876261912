export default class PolyfillController {
  constructor() {
    this.polyfills = [];
    this.loaded = [];
  }

  init() {
    this.loadPolyfills();

    return Promise.all(this.polyfills);
  }

  loadPolyfills() {
    this.forEach();
    this.from();
    this.includes();
    this.intersectionObserver();
    this.fetch();
    this.proxy();
    this.closest();
    this.before();
    this.remove();
    this.entries();
    this.assign();
    this.customEvent();
    this.url();
    this.objectFit();
  }

  forEach() {
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
      this.loaded.push('forEach');
    }
  }

  from() {
    if (!Array.from) {
      Array.from = (function () {
        let symbolIterator;
        try {
          symbolIterator = Symbol.iterator ?
            Symbol.iterator :
            'Symbol(Symbol.iterator)';
        } catch (e) {
          symbolIterator = 'Symbol(Symbol.iterator)';
        }

        let toStr = Object.prototype.toString;
        var isCallable = function (fn) {
          return (
            typeof fn === 'function' ||
            toStr.call(fn) === '[object Function]'
          );
        };
        let toInteger = function (value) {
          let number = Number(value);
          if (isNaN(number)) return 0;
          if (number === 0 || !isFinite(number)) return number;
          return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
        };
        let maxSafeInteger = Math.pow(2, 53) - 1;
        let toLength = function (value) {
          var len = toInteger(value);
          return Math.min(Math.max(len, 0), maxSafeInteger);
        };

        let setGetItemHandler = function setGetItemHandler(isIterator, items) {
          let iterator = isIterator && items[symbolIterator]();
          return function getItem(k) {
            return isIterator ? iterator.next() : items[k];
          };
        };

        let getArray = function getArray(
          T,
          A,
          len,
          getItem,
          isIterator,
          mapFn
        ) {
          // 16. Let k be 0.
          let k = 0;

          // 17. Repeat, while k < len… or while iterator is done (also steps a - h)
          while (k < len || isIterator) {
            let item = getItem(k);
            let kValue = isIterator ? item.value : item;

            if (isIterator && item.done) {
              return A;
            } else {
              if (mapFn) {
                A[k] =
                  typeof T === 'undefined' ?
                  mapFn(kValue, k) :
                  mapFn.call(T, kValue, k);
              } else {
                A[k] = kValue;
              }
            }
            k += 1;
          }

          if (isIterator) {
            throw new TypeError(
              'Array.from: provided arrayLike or iterator has length more then 2 ** 52 - 1'
            );
          } else {
            A.length = len;
          }

          return A;
        };

        // The length property of the from method is 1.
        return function from(arrayLikeOrIterator /*, mapFn, thisArg */ ) {
          // 1. Let C be the this value.
          var C = this;

          // 2. Let items be ToObject(arrayLikeOrIterator).
          var items = Object(arrayLikeOrIterator);
          var isIterator = isCallable(items[symbolIterator]);

          // 3. ReturnIfAbrupt(items).
          if (arrayLikeOrIterator == null && !isIterator) {
            throw new TypeError(
              'Array.from requires an array-like object or iterator - not null or undefined'
            );
          }

          // 4. If mapfn is undefined, then let mapping be false.
          var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
          var T;
          if (typeof mapFn !== 'undefined') {
            // 5. else
            // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
            if (!isCallable(mapFn)) {
              throw new TypeError(
                'Array.from: when provided, the second argument must be a function'
              );
            }

            // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
            if (arguments.length > 2) {
              T = arguments[2];
            }
          }

          // 10. Let lenValue be Get(items, "length").
          // 11. Let len be ToLength(lenValue).
          var len = toLength(items.length);

          // 13. If IsConstructor(C) is true, then
          // 13. a. Let A be the result of calling the [[Construct]] internal method
          // of C with an argument list containing the single item len.
          // 14. a. Else, Let A be ArrayCreate(len).
          var A = isCallable(C) ? Object(new C(len)) : new Array(len);

          return getArray(
            T,
            A,
            len,
            setGetItemHandler(isIterator, items),
            isIterator,
            mapFn
          );
        };
      })();

      this.loaded.push('from');
    }
  }

  includes() {
    if (!String.prototype.includes) {
      String.prototype.includes = function (search, start) {
        'use strict';
        if (typeof start !== 'number') {
          start = 0;
        }

        if (start + search.length > this.length) {
          return false;
        } else {
          return this.indexOf(search, start) !== -1;
        }
      };

      this.loaded.push('String.includes');
    }

    if (!Array.prototype.includes) {
      Object.defineProperty(Array.prototype, "includes", {
        enumerable: false,
        value: function (obj) {
          var newArr = this.filter(function (el) {
            return el == obj;
          });
          return newArr.length > 0;
        }
      });

      this.loaded.push('Array.includes');
    }
  }

  intersectionObserver() {
    if (!('IntersectionObserver' in window)) {
      this.polyfills.push(import( /* webpackChunkName: "polyfills/intersectionobserver" */ 'intersection-observer'));
      this.loaded.push('intersectionObserver');
    }
  }

  fetch() {
    if (!window.fetch) {
      this.polyfills.push(import( /* webpackChunkName: "polyfills/fetch" */ 'whatwg-fetch'));
      this.loaded.push('fetch');
    }
  }

  abortController() {
    if (typeof AbortController == 'undefined') {
      this.polyfills.push(import( /* webpackChunkName: "polyfills/abortController" */ 'abortcontroller-polyfill/dist/polyfill-patch-fetch'));
      this.loaded.push('abortController');
    }
  }

  proxy() {
    if (!('Proxy' in window)) {
      this.polyfills.push(import( /* webpackChunkName: "polyfills/proxy" */ 'es6-proxy-polyfill'));
      this.loaded.push('proxy');
    }
  }

  closest() {
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
      this.loaded.push('matches');
    }

    if (!Element.prototype.closest) {
      Element.prototype.closest = function (s) {
        var el = this;
        do {
          if (el.matches(s)) return el;
          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
      };
      this.loaded.push('closest');
    }
  }

  before() {
    (arr => {
      arr.forEach(function (item) {
        if (item.hasOwnProperty('before')) {
          return;
        }
        Object.defineProperty(item, 'before', {
          configurable: true,
          enumerable: true,
          writable: true,
          value: function before() {
            var argArr = Array.prototype.slice.call(arguments),
              docFrag = document.createDocumentFragment();

            argArr.forEach(function (argItem) {
              var isNode = argItem instanceof Node;
              docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
            });

            this.parentNode.insertBefore(docFrag, this);
          }
        });
      });
      this.loaded.push('before');
    })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
  }

  remove() {
    (arr => {
      arr.forEach(function (item) {
        if (item.hasOwnProperty('remove')) {
          return;
        }
        Object.defineProperty(item, 'remove', {
          configurable: true,
          enumerable: true,
          writable: true,
          value: function remove() {
            if (this.parentNode === null) {
              return;
            }
            this.parentNode.removeChild(this);
          }
        });
      });
      this.loaded.push('remove');
    })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
  }

  entries() {
    if (!Object.entries) {
      Object.entries = function (obj) {
        var ownProps = Object.keys(obj),
          i = ownProps.length,
          resArray = new Array(i); // preallocate the Array
        while (i--)
          resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
      };
      this.loaded.push('entries');
    }
  }

  assign() {
    if (typeof Object.assign !== 'function') {
      // Must be writable: true, enumerable: false, configurable: true
      Object.defineProperty(Object, "assign", {
        value: function assign(target, varArgs) { // .length of function is 2
          'use strict';
          if (target === null || target === undefined) {
            throw new TypeError('Cannot convert undefined or null to object');
          }

          let to = Object(target);

          for (let index = 1; index < arguments.length; index++) {
            let nextSource = arguments[index];

            if (nextSource !== null && nextSource !== undefined) {
              for (let nextKey in nextSource) {
                // Avoid bugs when hasOwnProperty is shadowed
                if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                  to[nextKey] = nextSource[nextKey];
                }
              }
            }
          }
          return to;
        },
        writable: true,
        configurable: true
      });
      this.loaded.push('assign');
    }
  }

  customEvent() {
    if (typeof (Event) === 'object') {
      function CustomEvent(event, params) {
        params = params || {
          bubbles: false,
          cancelable: false,
          detail: undefined
        };
        let evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      }

      CustomEvent.prototype = window.Event.prototype;

      window.CustomEvent = CustomEvent;
      window.Event = CustomEvent;
      this.loaded.push('Custom Event');
    }
  }

  url() {
    if (typeof window.URLSearchParams == 'undefined') {
      this.polyfills.push(import( /* webpackChunkName: "polyfills/url" */ 'url-polyfill'));
      this.loaded.push('url');
    }
  }

  objectFit() {
    if ('objectFit' in document.documentElement.style === false) {
      this.polyfills.push(import( /* webpackChunkName: "polyfills/objectFit" */ 'objectFitPolyfill').then(e => {
        window.objectFitPolyfill();
        return true;
      }));
      this.loaded.push('objectFit');
    }
  }
}