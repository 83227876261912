import BaseApi from './baseApi';

export default class Api extends BaseApi {
    constructor() {
        super();
        this.test = false;
        this.url = '/api/';
    }

    /**
     * @param {String} id GUID of decision tree
     */
    getDecisionTree(id) {
        return super.fetch(
            `decisiontree/tree`,
            this.methods.get,
            {
                id
            }
        )
    }

    /**
     * @param {String} id GUID of decision tree
     */
    getQuiz(id) {
        return super.fetch(
            `quiz`,
            this.methods.get,
            {
                id
            }
        )
    }

    /**
     * @param {String} path Path of url feedback was posted from
     * @param {String} feedback Either "Positive" or "Negative"
     * @param {String} comment Optional comment from user
     */
    postFeedback(path, feedback, comment = false) {
        const data = {
            Path: path,
            Feedback: feedback
        };

        if (comment && comment.toString().trim().length) {
            data.Comment = comment.toString().trim();
        }

        return super.fetch(
            `feedback/submit`,
            this.methods.post,
            data,
            {
                'Content-Type': 'application/json'
            }
        );
    }

    /**
     * @param {String} id 
     * @param {String} voteId
     */
    getPoll(id, voteId = false) {
        const data = {
            PollId: id,
        };
        if (voteId) {
            data.VoteId = voteId;
        }

        return super.fetch(
            `poll/find`,
            this.methods.get,
            data
        );
    }

    /**
     * 
     * @param {String} pollId 
     * @param {String} answerId 
     * @param {String} path 
     */
    postPoll(pollId, answerId, path) {
        return super.fetch(
            `poll/submit`,
            this.methods.post,
            {
                PollId: pollId,
                AnswerId: answerId,
                Path: path,
            },
            {
                'Content-Type': 'application/json'
            }
        );
    }
}