/**
 * Class representing an HTML element
 */
export default class CustomHTMLElement {
    /**
     * Creates the CustomHTMLElement
     * @param {object} defaultProperties - List of default element properties
     */
	constructor(defaultProperties = {}) {
		if (window.HTMLID === undefined) {
			window.HTMLID = 0;
		}
		window.HTMLID++;
		this._id = '_' + window.HTMLID;
		this._template = null;
		this._html = null;
		this._object = null;

		this._properties = defaultProperties;
	}

    /**
     * Either returns a generated HTMLElement or creates a new one
     * @param {boolean} [forceCreate=false] - Forces a new HTMLElement to be created
     * @returns {HTMLElement}
     */
	 getOrCreate(forceCreate = false) {
		if (this._template === null) {
			throw new Error('No template was set');
		}

		if (this._object !== null && !forceCreate) {
			return this._object;
		} else {
			let containerElement = document.createElement('div');
			this._html = this._template(this._properties);
			containerElement.innerHTML = this._html;
			this._object = containerElement.firstChild;

			return this._object;
		}
	}

	generate(forceRenew = false) {
		return this.getOrCreate(forceRenew);
	}

    /**
     * Set properties for the element
     * @param {object} properties - List of properties that can be set on creating a new element
     */
	setProperties(properties) {
		if (Object.keys(properties).length > 0) {
			Object.keys(properties).forEach(key => {
				if ((typeof this[`set${key.charAt(0).toUpperCase()}${key.substring(1)}`]) === 'function') {
					this[`set${key.charAt(0).toUpperCase()}${key.substring(1)}`](properties[key]);
				} else {
					this._properties[key] = properties[key];
				}
			});
		}
	}

    /**
     * Sets and returns one of the element's properties
     * @param {string} key - The property's name
     * @param {*} value - The property's value
     * @returns {*}
     */
	_property(key, value) {
		if (value === undefined) {
			return this._properties[key];
		} else {
			this._properties[key] = value;
			return this._properties[key];
		}
	}

	/**
	 * Creates getters and setters for all available properties
	 */
	 _createPropertyMethods() {
		Object.keys(this._properties).forEach(propertyKey => {
			let setKeyMethodName = `set${propertyKey.charAt(0).toUpperCase()}${propertyKey.substring(1)}`;

			// Creates the set{Keyname} property. This one should do some basic type verification to make sure a string stays a string etc.
			if (!(setKeyMethodName in this)) {
				Object.defineProperty(this, setKeyMethodName, {
					value: (givenValue = this._property(propertyKey)) => {
						return this._property(propertyKey, this._ensureType(givenValue, typeof this._property(propertyKey)));
					},
					enumerable: true,
					configurable: true,
				});
			}

			// Normal getter and setter. Setter passes the value to the set{Keyname} method
			Object.defineProperty(this, propertyKey, {
				get: () => this._property(propertyKey),
				set: (givenValue = this._property(propertyKey)) => {
					return this[setKeyMethodName](givenValue);
				},
				enumerable: true,
				configurable: true
			});
		});
	}

	/**
	 * Ensures the property's primitive type stays the same when updating it. Strings stay strings, numbers stay numbers etc.
	 * @param {*} value The value as received from the setter
	 * @param {String} type The primitive the value's supposed to be
	 * @returns {*} The value, possibly converted to the original's primitve
	 */
	_ensureType(value, type) {
		switch (type) {
			case 'string':
				return (value ? `${value}` : '');
			case 'number':
				return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
			case 'boolean':
				return (value.toString().toLowerCase() == 'true');
			default:
				return value;
		}
	}
}