export default class VideoController {
    constructor() {
        this.instances = [];
        this.vimeoPlayer = null;

        this.init();
    }

    init() {
        if (document.querySelector('section.video[data-type="youtube"]')) {
            const loadApi = this.loadYouTubeApi(); // Promise

            document.querySelectorAll('section.video[data-type="youtube"]').forEach(videoSection => {
                if (videoSection.classList.contains('initialized')) {
                    return;
                }
                videoSection.classList.add('initialized');
                
                const instance = {
                    type: 'YouTube',
                    section: videoSection
                };

                this.instances.push(instance);

                const trigger = videoSection.querySelector('a, button');
                trigger.addEventListener('click', e => {
                    console.log("clicked");
                    e.preventDefault();
                    console.log("preventDefault");
                    // Prepare video
                    //videoSection.classList.add('loading');
                    
                    const playerId = `video_${Math.random().toString(16).slice(2)}_${videoSection.dataset.id}`;
                    videoSection.querySelector('.player').id = playerId;
                    
                    loadApi.then(() => {
                        console.log("loadapi");
                        instance.player = new YT.Player(playerId, {
                            height: '500',
                            width: '888',
                            videoId: videoSection.dataset.id,
                            playerVars: {
                                'playsinline': 1,
                            },
                            events: {
                                onReady: evt => {
                                    evt.target.playVideo();
                                    trigger.remove();
                                },
                                onError: evt => {
                                    console.log("onError");
                                    videoSection.classList.add('error');
                                    trigger.classList.add('d-none');
                                }
                            }
                        });
                    });
                });
            });
        }

        if (document.querySelector('section.video[data-type="vimeo"]')) {
            const loadApi = this.loadVimeoApi(); // Promise

            document.querySelectorAll('section.video[data-type="vimeo"]').forEach(videoSection => {
                const instance = {
                    type: 'Vimeo',
                    section: videoSection
                };

                this.instances.push(instance);

                const trigger = videoSection.querySelector('a, button');
                trigger.addEventListener('click', e => {

                    // Prepare video
                    videoSection.classList.add('loading');
                    
                    const playerId = `video_${Math.random().toString(16).slice(2)}_${videoSection.dataset.id}`;
                    videoSection.querySelector('.player').id = playerId;

                    loadApi.then(() => {
                        instance.player = new this.vimeoPlayer(playerId, {
                            height: '500',
                            width: '888',
                            id: videoSection.dataset.id,
                        });

                        videoSection.classList.remove('loading');
                        trigger.classList.add('toHide');
                        setTimeout(() => {
                            trigger.classList.add('d-none');
                        }, 600);

                        instance.player.ready().then(() => {
                            instance.player.play();
                        });
                    });
                });
            });
        }
    }

    loadYouTubeApi() {
        return new Promise(resolve => {
            if (window.YT && window.YT.Player) {
                resolve();
            } else {
                window.onYouTubeIframeAPIReady = resolve; //window.onYouTubeIframeAPIReady ||
                const tag = document.createElement('script');
                tag.src = 'https://www.youtube.com/iframe_api';
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }
        });
    }

    loadVimeoApi() {
        return new Promise(resolve => {
            if (this.vimeoPlayer) {
                resolve();
            } else {
                import( /* webpackChunkName: "modules/vimeo" */ '@vimeo/player').then(e => {
                    this.vimeoPlayer = e.default;
                    resolve();
                });
            }
        });
    }
}