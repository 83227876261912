export default class App {
	constructor() {
		this._resizeFunctionsArr = [];
		this._resizeFunctions = {};
		this._resizeFunctionsCounter = 0;
		this._resizeTimeout = null;
		this.resizeThrottle = false;

		this._scrollFunctionsArr = [];
		this._scrollFunctions = {};
		this._scrollFunctionsCounter = 0;
		this._scrollTimeout = null;
		this.scrollThrottle = false;

		this._resizeAnimationPreventerTimeout = 200;

		this.newPageWarning = true;

		this.selectricCBs = [];

		this.breakpoints = {
			xs: {
				gutter: 40,
				size: 0,
			},
			sm: {
				gutter: 40,
				size: 375,
			},
			md: {
				gutter: 40,
				size: 768,
			},
			ml: {
				gutter: 40,
				size: 1024,
			},
			lg: {
				gutter: 40,
				size: 1224,
			},
		}

		this.animationDuration = 300;
	}

	init() {
		this.detectBrowser();
		this.setEventListeners();
		this.fullHeightListener();
		this.resizeAnimationPreventer();
		this.initCSSBreakpoints();
		this.addNewWindowTitles();
		this.addNoOpener();
		this.addAnchorEnhancement();
		this.initSelectric();
		this.initTippy();
		this.initCopy();
		this.initScrollTo();
		this.initToTop();
		// this.initScrollToTop();
		this.initPrintListener();
		this.initLabelClick();
		// this.initSlimSelect();
		
		return true;
	}

	setEventListeners() {
		window.addEventListener('resize', this.resize.bind(this), {
			passive: true
		});
		document.addEventListener('scroll', this.scroll.bind(this), {
			passive: true
		});

		document.querySelectorAll('main form label').forEach(label => {
			label.addEventListener('keydown', e => {
				if (e.key.toLowerCase().substr(0, 3) == 'ent') {
					label.click();
				}
			});
		});

		Array.from(document.querySelectorAll('a')).filter(a => !a.hasAttribute('href')).forEach(a => {
			if (!a.hasAttribute('tabindex')) {
				a.setAttribute('tabindex', '0');
			}

			a.addEventListener('keydown', e => {
				if (e.key.toLowerCase().substr(0, 3) == 'ent') {
					a.click();
				}
			});
		});
	}

	resize() {
		const runFunctions = () => {
			for (let i = 0, length = this._resizeFunctionsArr.length; i < length; i++) {
				this._resizeFunctionsArr[i]();
			}
		};
		if (this._resizeTimeout !== null) {
			return;
		}
		runFunctions();
		if (this.resizeThrottle) {
			this._resizeTimeout = setTimeout(() => {
				this._resizeTimeout = null;
				runFunctions();
			}, this.resizeThrottle);
		}
	}

	/**
	 * @param {function} func Function to be called on a resize event
	 */
	registerResizeEvent(func) {
		this._resizeFunctions[this._resizeFunctionsCounter] = func;
		this._resizeFunctionsCounter = this._resizeFunctionsCounter + 1;

		// Rebuild array of events
		this._resizeFunctionsArr = [];
		Object.keys(this._resizeFunctions).forEach(key => {
			this._resizeFunctionsArr.push(this._resizeFunctions[key]);
		});

		return this._resizeFunctionsCounter - 1;
	}

	/**
	 * @param {Number} func Function's ID to be removed from the resize functions
	 */
	unregisterResizeEvent(func) {
		if (this._resizeFunctions[func]) {
			// Found
			delete this._resizeFunctions[func];

			// Rebuild array of events
			this._resizeFunctionsArr = [];
			Object.keys(this._resizeFunctions).forEach(key => {
				this._resizeFunctionsArr.push(this._resizeFunctions[key]);
			});

			return true;
		} else {
			// Not found
			return false;
		}
	}

	/**
	 * @description Fires all registered events for a scroll event in the document
	 */
	scroll() {
		const runFunctions = () => {
			for (let i = 0, length = this._scrollFunctionsArr.length; i < length; i++) {
				this._scrollFunctionsArr[i]();
			}
		};
		if (this._scrollTimeout !== null) {
			return;
		}
		runFunctions();
		if (this.scrollThrottle) {
			this._scrollTimeout = setTimeout(() => {
				this._scrollTimeout = null;
				runFunctions();
			}, this.scrollThrottle);
		}
	}

	/**
	 * @param {function} func Function to be called on a scroll event
	 */
	registerScrollEvent(func) {
		this._scrollFunctions[this._scrollFunctionsCounter] = func;
		this._scrollFunctionsCounter = this._scrollFunctionsCounter + 1;

		// Rebuild array of events
		this._scrollFunctionsArr = [];
		Object.keys(this._scrollFunctions).forEach(key => {
			this._scrollFunctionsArr.push(this._scrollFunctions[key]);
		});

		return this._scrollFunctionsCounter - 1;
	}

	/**
	 * @param {Number} func Function's ID to be removed from the scroll functions
	 */
	unregisterScrollEvent(func) {
		if (this._scrollFunctions[func]) {
			// Found
			delete this._scrollFunctions[func];

			// Rebuild array of events
			this._scrollFunctionsArr = [];
			Object.keys(this._scrollFunctions).forEach(key => {
				this._scrollFunctionsArr.push(this._scrollFunctions[key]);
			});

			return true;
		} else {
			// Not found
			return false;
		}
	}

	/**
	 * @description Adds a 'preventAnimations' class to the html tag on resizing
	 */
	resizeAnimationPreventer() {
		this.resizeTimer = null;
		this.windowWidth = window.innerWidth;
		this.registerResizeEvent(() => {
			if (window.innerWidth !== this.windowWidth) {
				document.documentElement.classList.add('preventAnimations');
				clearTimeout(this.resizeTimer);

				this.resizeTimer = setTimeout(() => {
					document.documentElement.classList.remove('preventAnimations');
				}, this._resizeAnimationPreventerTimeout);

				this.windowWidth = window.innerWidth;
			}
		});
	}

	/**
	 * @description Adds a CSS variable '--fullHeight' to the html tag that creates an 'accurate 100vh'
	 */
	fullHeightListener() {
		const fullHeight = () => {
			document.documentElement.style.setProperty('--fullHeight', `${window.innerHeight}px`);
		};
		this.registerResizeEvent(fullHeight);
		fullHeight();
	}

	/**
	 * @description Adds a browser-specific class to the html tag
	 */
	detectBrowser() {
		// Opera 8.0+
		let isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

		// Firefox 1.0+
		let isFirefox = typeof InstallTrigger !== 'undefined';

		let isIphone = window.navigator.userAgent.match(/iPhone/i);

		let isIpad = window.navigator.userAgent.match(/iPad/i);

		let isSafari = window.navigator.userAgent.match(/Safari/i) && (window.navigator.userAgent.match(/iPhone/i) || window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/macOS/i));

		// Internet Explorer 6-11
		let isIE = /*@cc_on!@*/ false || !!document.documentMode;

		// Edge 20+
		let isEdge = !isIE && !!window.StyleMedia;

		// Chrome
		let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

		// Blink engine detection
		let isBlink = (isChrome || isOpera) && !!window.CSS;

		if (isOpera) {
			document.documentElement.classList.add('opera');
		}

		if (isFirefox) {
			document.documentElement.classList.add('firefox');
		}

		if (isSafari) {
			document.documentElement.classList.add('safari');
		}

		if (isIphone) {
			document.documentElement.classList.add('iphone');
		}

		if (isIpad) {
			document.documentElement.classList.add('ipad');
		}

		if (isIE) {
			document.documentElement.classList.add('ie');
		}

		if (isEdge) {
			document.documentElement.classList.add('edge');
		}

		if (isChrome) {
			document.documentElement.classList.add('chrome');
		}

		if (isBlink) {
			document.documentElement.classList.add('blink');
		}
	}

	/**
	 * @description Attempts to load bootstrap breakpoint settings from the CSS variables
	 */
	initCSSBreakpoints() {
		if (window.getComputedStyle) {
			const documentStyling = getComputedStyle(document.documentElement);
			Object.keys(this.breakpoints).forEach(breakpoint => {
				if (documentStyling.getPropertyValue(`--breakpoint-${breakpoint}-size`)
					&& parseInt(documentStyling.getPropertyValue(`--breakpoint-${breakpoint}-size`)) !== NaN) {
					this.breakpoints[breakpoint].size = parseInt(documentStyling.getPropertyValue(`--breakpoint-${breakpoint}-size`));
				}

				if (documentStyling.getPropertyValue(`--breakpoint-${breakpoint}-gutter`)
					&& parseInt(documentStyling.getPropertyValue(`--breakpoint-${breakpoint}-gutter`)) !== NaN) {
					this.breakpoints[breakpoint].gutter = parseInt(documentStyling.getPropertyValue(`--breakpoint-${breakpoint}-gutter`));
				}
			});

			// Also check if we can save animationDuration
			if (documentStyling.getPropertyValue(`--animationDuration`)
					&& parseInt(documentStyling.getPropertyValue(`--animationDuration`)) !== NaN) {
				this.animationDuration = parseInt(documentStyling.getPropertyValue(`--animationDuration`));
			}
		}
	}

	/**
	 * @description Appends text to a tags that tells the user it'll open in a new tab/window. Add a tag with `data-new-window-text` property to change the appended text
	 */
	addNewWindowTitles() {
		let text = '(opent in een nieuw scherm)';
		if (document.querySelector('[data-new-window-text]')) {
			text = document.querySelector('[data-new-window-text]').dataset.newWindowText;
		}

		document.querySelectorAll('a[target="_blank"]').forEach(a => {
			a.setAttribute('title', `${a.getAttribute('title') ? a.getAttribute('title') : ''} ${text}`.trim().replace(/  /g, " "));
		});
	}

	/**
	 * @description Adds the attribute 'rel' set to 'noopener' on external links; This is for security reasons/best practices
	 */
	addNoOpener() {
		document.querySelectorAll('a').forEach(a => {
			if (a.href && !a.hasAttribute('rel')) {
				if (a.hostname != location.hostname) {
					a.setAttribute('rel', 'noopener');
				}
			}
		});
	}

	/**
	 * @description Makes sure anchored tags get a class for it, so offsetting scroll is possible. Also edit anchor links to get the [data-scroll-to] attribute
	 */
	addAnchorEnhancement() {
		// Enhance anchors
		Array.from(document.querySelectorAll('a[id]')).filter(a => !a.href).forEach(a => {
			// Get parent element, add 'anchored' class
			if (a.parentElement) {
				a.parentElement.classList.add('anchored');
			}
		});

		// Enhance anchor links
		Array.from(document.querySelectorAll('a[href]')).filter(a => a.hash && a.hash != '' && a.getAttribute('href').substr(0, 1) == '#' && !a.dataset.scrollTo).forEach(a => {
			a.dataset.scrollTo = a.hash;
		});
	}

	/**
	 * @description Scroll element into view
	 */
	initScrollTo() {
		if (window.scrollTo) {
			document.querySelectorAll('[data-scroll-to]').forEach(el => {
				el.addEventListener('click', e => {
					e.preventDefault();
					const target = document.querySelector(el.dataset.scrollTo);
					console.log(target);
	
					const scrollPosition = this.elementCoords(target).top - (window.innerWidth > this.breakpoints.md.size ? 150 : 70);
					window.scrollTo({
						top: scrollPosition,
						behavior: 'smooth',
					});
				});
			});
		}
	}

	scrollTo(selector) {
		let target;
		if (typeof selector == 'string') {
			target = document.querySelector(selector);
		} else {
			target = selector;
		}
	
		if (target) {
			const scrollPosition = this.elementCoords(target).top - (window.innerWidth > this.breakpoints.md.size ? 150 : 70);
			window.scrollTo({
				top: scrollPosition,
				behavior: 'smooth',
			});
		}
	}

	/**
	 * @description Basic random number generator
	 * @param {Number} min 
	 * @param {Number} max 
	 * @returns {Number}
	 */
	random(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	/**
	 * @description Basic random string generator
	 * @param {Number} length
	 * @returns {String}
	 */
	randomString(length) {
		let result = '';
		const characters = 'AB CDE FG HIJKLM NO PQRST UVWXYZa bcde fghi jklmnop qrstuvwx yz0 12345 6789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
		  	result += characters.charAt(Math.floor(Math.random() * charactersLength));
	   	}
	   	return result.trim().replace(/\s\s/g, ' ');
	}

	uuid() {
		let
			d = new Date().getTime(),
			d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
			let r = Math.random() * 16;
			if (d > 0) {
				r = (d + r) % 16 | 0;
				d = Math.floor(d / 16);
			} else {
				r = (d2 + r) % 16 | 0;
				d2 = Math.floor(d2 / 16);
			}
			return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
		});
	}

	/**
	 * @description Retrieves absolute position of an element relative to the page
	 * @param {HTMLElement} el 
	 * @returns {Object}
	 */
	elementCoords(el) {
		const box = el.getBoundingClientRect();

		const body = document.body;
		const docEl = document.documentElement;

		const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
		const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

		const clientTop = docEl.clientTop || body.clientTop || 0;
		const clientLeft = docEl.clientLeft || body.clientLeft || 0;

		const top  = box.top +  scrollTop - clientTop;
		const left = box.left + scrollLeft - clientLeft;

		return { top: Math.round(top), left: Math.round(left) };
	}

	initScrollToTop() {
		this.scrollToTopContainer = document.querySelector('.scrollToTop');

		if (this.scrollToTopContainer) {
			const button = this.scrollToTopContainer.querySelector('button');
			const scroll = () => {
				if (document.documentElement.scrollTop > 400) {
					this.scrollToTopContainer.classList.add('active');
				} else {
					this.scrollToTopContainer.classList.remove('active');
				}
			};

			this.registerScrollEvent(scroll.bind(this));

			button.addEventListener('click', () => {
				this.scrollToTopContainer.classList.add('moving');
				button.blur();
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
				setTimeout(() => this.scrollToTopContainer.classList.remove('moving'), 1500);
			});
		}
	}

	loadjQuery() {
		return new Promise((resolve) => {
			if (window.$) {
				resolve(true);
			} else if (this.jqueryImport) {
				return this.jqueryImport;
			} else {
				this.jqueryImport = import( /* webpackChunkName: "modules/jquery" */ 'jquery').then(e => {
					window.$ = e.default;
					delete this.jqueryImport;
					resolve(true);
				});
			}
		});
	}

	initSelectric() {
		if (document.querySelectorAll('select[selectric="true"]').length) {
			try {
				this.loadjQuery().then(() => {
					import( /* webpackChunkName: "modules/dropdown" */ 'selectric').then(() => {

						document.querySelectorAll('select[selectric="true"]').forEach(select => {
							$(select).selectric({
								arrowButtonMarkup: `<b class="button"><svg class="sprite icon_link_chevron"><use xlink:href="#icon_link_chevron"></use></svg></b>`
							});

							// Add a hidden label around the selectric input for accessibility.
							$('.selectric-input').wrap('<label class="d-none" aria-hidden="true">Dropdown</label>');
	
							this.selectricCBs.forEach(cb => {
								cb();
							});
	
							this.selectricCBs = [];
						});
					});
				});
			} catch (e) {
				console.error(e);
			}
		}
	}

	/**
	 * @description Tooltip library
	 */
	initTippy() {
		if (document.querySelectorAll('[data-tippy-content]').length || document.querySelectorAll('[data-tippy-source]').length) {
			try {
				import( /* webpackChunkName: "modules/tooltip" */ 'tippy.js').then(e => {
					const tippy = e.default;
					tippy('[data-tippy-content]');

					if (document.querySelectorAll('[data-tippy-source]').forEach(source => {
							const target = document.querySelector(`[data-tippy-target="${source.dataset.tippySource}"]`);
							if (target) {
								tippy(source, {
									allowHTML: true,
									content: target.innerHTML,
									interactive: target.querySelectorAll('a, button').length > 0
								});
							}
						}));
				});
			} catch (e) {
				console.error(e);
			}
		}
	}

	/**
	 * @description Makes tags with the data-copy property clickable, copies its content to the clipboard
	 */
	initCopy() {
		document.querySelectorAll('[data-copy]').forEach(el => {
			el.addEventListener('click', () => {
				try {
					const copyContent = el.dataset.copy;
					const input = document.createElement('input');

					input.type = 'text';
					input.style.position = 'absolute';
					input.style.height = 0;
					input.style.overflow = 'hidden';
					input.value = copyContent;

					document.body.appendChild(input);
					input.select();
					document.execCommand('copy');
					input.parentNode.removeChild(input);
				} catch (e) {
					this.toast('Something unexpected went wrong', 'error');
					console.error(e);
				}
			});
		});
	}

	initPrintListener() {
		this.printTexts = Array.from(document.querySelectorAll('[data-print]'));

		if (this.printTexts.length) {
			let printElements = [];

			window.addEventListener('beforeprint', e => {
				this.printTexts.forEach(printParent => {
					let span = document.createElement('span');
					span.innerHTML = printParent.dataset.print;
					span.classList.add('printText');

					printElements.push(span);

					printParent.appendChild(span);
				});
			});

			window.addEventListener('afterprint', e => {
				printElements.forEach(printElement => {
					printElement.remove();
				});

				printElements = [];
			});
		}
	}

	initToTop(){
		// const toTopButton = document.querySelector('.toTop');

		// if(toTopButton){
		// 	toTopButton.addEventListener('click', e => {
		// 		this.scroll(0,0);
		// 	})
		// }

		this.scrollToTopContainer = document.querySelector('.toTop');

		if (this.scrollToTopContainer) {
			const scroll = () => {
				if (document.documentElement.scrollTop > 400) {
					this.scrollToTopContainer.classList.add('active');
				} else {
					this.scrollToTopContainer.classList.remove('active');
				}
			};

			this.registerScrollEvent(scroll.bind(this));

			this.scrollToTopContainer.addEventListener('click', () => {
				this.scrollToTopContainer.classList.add('moving');
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
				setTimeout(() => this.scrollToTopContainer.classList.remove('moving'), 1500);
			});
		}
		
	}

	initLabelClick() {
		document.querySelectorAll('label[for]').forEach(label => {
			label.addEventListener('keydown', e => {

				switch (e.key.toLowerCase()) {
					case ' ':
						e.preventDefault();
					case 'space':
					case 'enter':
						label.click();
						break;
				}
			});
		});
	}

	// initSlimSelect() {
	// 	if(document.querySelector('.slimSelect')){
	// 		console.log('Slim select found!');
	// 		import( /* webpackChunkName: "modules/tooltip" */ 'tippy.js').then(e => {
				
	// 		});


	// 		new SlimSelect({
	// 			select: '#single'
	// 	  	})
	// 	}
	// }

	// initAutoplayOnHover() {
	// 	this.videoBlocks = this.videoBlocks || [];

	// 	Array.from(document.querySelectorAll('.promotionBlock, .videoBlock')).forEach(block => {
	// 		if (block.querySelector('video') && !block.classList.contains('autoThumbnail')) {
	// 			const video = block.querySelector('video');
	// 			video.src = video.src + '#t=0.001';
	// 			video.muted = true;
	// 			video.loop = true;
	// 			video.preload = 'metadata';
	// 			video.controls = false;
	
	// 			['mouseenter', 'touchstart'].forEach(event => {
	// 				block.addEventListener(event, e => {
	// 					video.play();
	// 				});
	// 			});

	// 			['mouseleave', 'touchend'].forEach(event => {
	// 				block.addEventListener(event, e => {
	// 					video.pause();
	// 				});
	// 			});
	
	// 			this.videoBlocks.push({
	// 				block,
	// 				video,
	// 			});

	// 			block.classList.add('autoThumbnail');
	// 		}
	// 	});
	// }

	getCurrentBreakpoint() {
		const width = window.innerWidth;
		const breakpoints = Object.keys(this.breakpoints).reverse();

		let currentBreakpoint = breakpoints[breakpoints.length - 1];

		breakpoints.forEach(breakpoint => {
			if (width <= this.breakpoints[breakpoint].size) {
				currentBreakpoint = breakpoint;
			}
		});

		return currentBreakpoint;
	}

	toast(message, type = 'success') {
		if (this.ToastController) {
			this.ToastController.new(message, type);
		} else {
			console.error('ToastController not available. Message below');
			console.log(`[${type}] ${message}`);
		}
	}

	getParameter(name) {
		let result = null,
			tmp = [];
		let items = location.search.substr(1).split("&");
		for (let index = 0; index < items.length; index++) {
			tmp = items[index].split("=");
			if (tmp[0] === name) {
				result = decodeURIComponent(tmp[1]);
			}
			if (result === 'undefined') {
				result = true;
			}
		}
		return result;
	}

	insertTesters() {
		this.insertBreakpointIndicator();
		this.insertColumnTester();
	}

	insertColumnTester() {
		let tester = document.createElement('div');
		tester.classList.add('columnTester');
		let cols = ``;
		for (let i=0;i<12;i++) {
			cols += `<div class="col-1"><div></div></div>`;
		}
		tester.innerHTML = `<div class="container"><div class="row">${cols}</div></div>`;
		document.body.appendChild(tester);
	}

	insertBreakpointIndicator() {
		const breakpoints = Object.keys(this.breakpoints);
		let blocks = ``;
		breakpoints.forEach((breakpoint, index) => {
			let classes = 'col ';
			if (index == 0) {
				classes += `d-${breakpoints[index + 1]}-none`;
			} else if (index == breakpoints.length - 1) {
				classes += `d-none d-${breakpoint}-block`;
			} else {
				classes += `d-none d-${breakpoint}-block d-${breakpoints[index + 1]}-none`;
			}
			blocks += `<div class="${classes}"><div class="breakpointIndicator">${breakpoint}</div></div>`;
		});

		
		let tester = document.createElement('div');
		tester.classList.add('breakpointIndicators');

		tester.innerHTML = `<div class="container"><div class="row">${blocks}</div></div>`;
		document.body.appendChild(tester);
	}

	/**
	 * @description Part of the pagecontroller, makes sure newPage methods get executed in other controllers as well
	 */
	 newPage() {
		// Reset App's cache
		this._resizeFunctionsArr = [];
		this._resizeFunctions = {};
		this._resizeFunctionsCounter = 0;

		this._scrollFunctionsArr = [];
		this._scrollFunctions = {};
		this._scrollFunctionsCounter = 0;

		// Trigger slimmage
		window.slimmage.cr();

		// Trigger newPage in controllers
        let incompleteControllers = [];
        Object.keys(this).forEach(key => {
            if (this.hasOwnProperty(key) && key.indexOf('Controller') > -1) {
                const obj = this[key];
                if (obj != null && obj.newPage && typeof obj.newPage == 'function') {
                    obj.newPage();
                } else {
                    if (this.newPageWarning) {
                        incompleteControllers.push(key);
                    }
                }
            }
        });

		// Warn (once) if there are controllers without a newPage method
        if (this.newPageWarning && incompleteControllers.length) {
            console.warn(`The following controllers are missing a %cnewPage`, 'color: orange;', `method:\n\n   `, incompleteControllers.join('\n    '), `\n\nMake sure these can handle loading a new page`);
            delete this.newPageWarning;
        }
    }

	reset() {
		// this.initAutoplayOnHover();
	}
}