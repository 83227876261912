export default class StickyCTAController {
    constructor() {
        this.container = null;

        if (document.querySelector('.stickyctaWrapper')) {
            this.container = document.querySelector('.stickyctaWrapper');
            this.init();
        }
    }

    init() {
        this.setEventListener();
    }

    setEventListener() {
        if (document.querySelector('[class^="hero"]')) {
            document.addEventListener('scroll', (e) => {
                const hero = document.querySelector('[class^="hero"]').getBoundingClientRect();
                const herobottom = hero.bottom;
                
                if (herobottom < 0) {
                    this.show();
                } else {
                    this.hide();
                }
               

                if (this.bottomVisible()) {
                    this.container.classList.add('darkBlue');
                }
                else {
                    this.container.classList.remove('darkBlue');
                }

            });
        }    
    }

    show() {
        this.container.classList.remove('hidden', 'd-none');
    }

    hide() {
        this.container.classList.add('hidden');
    }

    bottomVisible() {
        return document.documentElement.clientHeight + window.scrollY >=
            (document.documentElement.scrollHeight ||
            document.documentElement.clientHeight);
    
    }
}