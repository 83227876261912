import Share from './share';

export default class ShareController {
    constructor() {
        this.selector = '.sharePageContainer';
        this.instances = [];

        console.log("sharecontroller: ", this.selector);

        this.init();
    }

    init() {
        if (document.querySelectorAll(this.selector).length) {
            document.querySelectorAll(this.selector).forEach(container => {
                const share = new Share(container);
                this.instances.push(share);

                share.init();
            })
        }
    }
}