import SlimSelect from 'slim-select'

export default class SlimSelectController {
    constructor() {
        this.currentSelect;
        this.placeholder;
        this.anchorMenuList;

        if(document.querySelector('.slimSelect')){
        this.placeholder = document.querySelector('select option:first-child').textContent;
        this.anchorMenuList = document.querySelector('.anchorMenuList');

        if(document.querySelector('.slimSelect')){
			console.log('Slim select found!');
			this.init();
		    }
        }
    }

    init() {
        const select = new SlimSelect({
            select: '#single',
            settings: {
                placeholderText: this.placeholder,
                showSearch: false,
            },
            events: {
                afterChange: (newVal) => {
                    handleSelect(newVal);

                }
              }
        })

        const handleSelect = (newVal) => {
            const value = newVal[0].value
                console.log(newVal)
                if(this.anchorMenuList){
                    this.anchorMenuList.querySelectorAll('a').forEach(a => {
                        if(a.getAttribute('href') == value){
                            a.click();
                        }
                    });
                }
            select.setSelected('placeholder');       
        }
    }
}
