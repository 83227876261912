import 'lazysizes';
import 'promise-polyfill/src/polyfill';

// App
import App from './app';
import PolyfillController from './controllers/polyfill';

// GTM
import GTMController from './controllers/gtm';

// API
import Api from './controllers/api/api';

// Controllers
import RibbonController from './controllers/ribbon';
import StickyCTAController from './controllers/stickycta';
import HeaderController from './controllers/header';
import VideoController from './controllers/video';
import FaqSearchController from './controllers/faqSearchController';
import InfoGuideAnchorMenu from './controllers/infoGuideAnchorMenu';
import SlimSelectController from './controllers/slimSelect';
import ShareController from './controllers/share/controller'
// import MediaController from './controllers/media';
// import AVGController from './controllers/avg';
// import FormController from './controllers/form';
// import ToastController from './controllers/toast/controller';
// import SliderController from './controllers/slider';
// import ParallaxController from './controllers/parallax';

(async () => {
    document.querySelector('html').classList.remove('no-js');

    const polyfills = new PolyfillController();
    await polyfills.init();

    try {
        const app = new App();
        app.resizeThrottle = 10;
        app.scrollThrottle = 100;
        window.app = app;
    
        app.polyfillController = polyfills;
        app.GTM = new GTMController();
        app.api = new Api();
        app.ribbonController = new RibbonController();
        app.stickyctaController = new StickyCTAController();
        app.headerController = new HeaderController();
        app.videoController = new VideoController();
        app.faqSearchController = new FaqSearchController();
        app.InfoGuideAnchorMenu = new InfoGuideAnchorMenu();
        app.slimSelectController = new SlimSelectController();
        app.shareController = new ShareController();
    /* 
        app.AVGController = new AVGController();
        app.mediaController = new MediaController();
        app.formController = new FormController();
        app.toastController = new ToastController();
        app.sliderController = new SliderController();
        app.parallaxController = new ParallaxController(); */

        // Modules
        const loadModules = () => {
            if (app.getParameter('debugger')) {
                import( /* webpackChunkName: "modules/console" */ './controllers/debugger').then(e => {
                    app.debuggerController = new e.default();
                });
            }

            // if (document.querySelector('.shareContainer')) {
            //     import( /* webpackChunkName: "modules/share" */ './controllers/share/controller').then(e => {
            //         app.shareController = new e.default();
            //     });
            // }

            if (document.querySelector('.accordion')) {
                import( /* webpackChunkName: "modules/accordion" */ './controllers/accordion/controller').then(e => {
                    app.accordionController = new e.default();
                });
            }

            if (document.querySelector('.swiper-container')) {
                import( /* webpackChunkName: "modules/slider" */ './controllers/slider').then(e => {
                    app.sliderController = new e.default();
                });
            }

            if (document.querySelector('[data-decision-tree]')) {
                import( /* webpackChunkName: "modules/decisionTree" */ './controllers/decisionTree/controller').then(e => {
                    app.decisionTreeController = new e.default();
                });
            }

            if (document.querySelector('[data-quiz]')) {
                import( /* webpackChunkName: "modules/quiz" */ './controllers/quiz/controller').then(e => {
                    app.quizController = new e.default();
                });
            }

            if (document.querySelector('[data-gallery]')) {
                import( /* webpackChunkName: "modules/gallery" */ './controllers/gallery').then(e => {
                    app.galleryController = new e.default();
                });
            }

            if (document.querySelector('.tabs')) {
                import( /* webpackChunkName: "modules/tabs" */ './controllers/tabs/controller').then(e => {
                    app.tabsController = new e.default();
                });
            }

            if (document.querySelector('section.tripCalculator')) {
                import(/* webpackChunkName: "modules/tripCalculator" */ './controllers/tripCalculator/controller').then(e => {
                    app.tripCalculatorController = new e.default();
                });
            }

            if (document.querySelector('section.feedbackBlock')) {
                import(/* webpackChunkName: "modules/feedback" */ './controllers/feedback/controller').then(e => {
                    app.feedbackController = new e.default();
                });
            }

            if (document.querySelector('section.pollWidget')) {
                import(/* webpackChunkName: "modules/poll" */ './controllers/poll/controller').then(e => {
                    app.pollController = new e.default();
                });
            }

            if (document.querySelector('section.videoGallery')) {
                import(/* webpackChunkName: "modules/videoGallery" */ './controllers/videoGallery').then(e => {
                    app.videoGalleryController = new e.default();
                });
            }

            if (document.querySelector('[data-quickstart]')) {
                import(/* webpackChunkName: "modules/quickstart" */ './controllers/quickstart').then(e => {
                    app.quickstartController = new e.default();
                });
            }

            if (document.querySelector('[data-anchor-list]')) {
                import(/* webpackChunkName: "modules/generateAnchorList" */ './controllers/generateAnchorList').then(e => {
                    app.generateAnchorList = new e.default();
                });
            }

            if (document.querySelector('[data-grid-column-list]')) {
                import(/* webpackChunkName: "modules/gridColumnList" */ './controllers/gridColumnList').then(e => {
                    app.gridColumnList = new e.default();
                });
            }
        }

        loadModules();
        await app.init();
        // app.insertTesters();
    } catch(e) {
        console.error(`Something went wrong initializing the app`);
        throw e;
    };

    console.log(`App initialized`);
    document.documentElement.classList.remove('preventAnimations');

})();