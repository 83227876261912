export default class InfoGuideAnchorMenu {
    constructor() {
        this.anchorMenu = null;
        this.dropdown = null;
        this.anchorMenuList = null;

        if (document.querySelector('.widget.anchorMenu')) {
            this.init();
        }
    }

    init() {
        console.log('anchormenu loaded');
        this.anchorMenu = document.querySelector('.widget.anchorMenu');
        this.dropdown = this.anchorMenu.querySelector('select.slimSelect');
        this.anchorMenuList = this.anchorMenu.querySelector('.anchorMenuList ul');
        
        if ('AbortController' in window) {
            this.abortController = new AbortController();
        }

        this.getWidgets();
    }

    // Retreive all current widgets on the page
    getWidgets(){
        document.querySelectorAll('.infoguideAnchor').forEach(widget => {
            console.log(this.anchorMenuList);
            console.log(this.dropdown);

            const li = document.createElement("li", { is: "expanding-list" });
            const a = document.createElement("a");
            const option = document.createElement("option");

            const displayName = widget.getAttribute("data-displayname");

            a.innerText = displayName;
            a.setAttribute('href', `#${widget.getAttribute("id")}`);
            a.setAttribute('title', 'Ga naar de pagina ' + displayName);
            option.setAttribute('value', `#${widget.getAttribute("id")}`);
            option.setAttribute('title', 'Ga naar de pagina ' + displayName);
            
            li.appendChild(a);
            option.innerText = widget.getAttribute("data-displayname");

            this.anchorMenuList.appendChild(li);
            this.dropdown.appendChild(option);
        });
    }
}