/**
 * @format
 */
import SearchController from './search';

// import TinyGesture from 'tinygesture';
export default class HeaderController {
    constructor() {
        this.header = null;
        this.panelContainer = null;
        this.panels = [];
        this.activePanel = null;

        this.videos = [];

        this.previousScroll = 0;
        this.scrollCounter = 0;
        this.threshold = 500;

        this.init();
        this.resize();
        this.scroll();

        this.searchController = new SearchController();
    }

    init() {
        this.header = document.querySelector('body > header');
        this.panelContainer = document.querySelector('.menuPanels');
        this.panelContainer
            .querySelectorAll('.panel')
            .forEach(panel => this.panels.push(panel));
        this.videos = document.querySelectorAll('video');

        this.previousScroll = window.pageYOffset;

        // Update background in advance
        // this.updateBackgroundSize();

        // Register events
        window.app.registerResizeEvent(this.resize.bind(this));
        window.app.registerScrollEvent(this.scroll.bind(this));

        // Click events
        this.header
            .querySelectorAll('[data-panel-opens]')
            .forEach(panelOpener => {
                let panelId = panelOpener.dataset.panelOpens;
                if (
                    this.panels
                        .map(panel => panel.dataset.panel)
                        .includes(panelId)
                ) {
                    panelOpener.addEventListener('click', e => {
                        e.preventDefault();
                        this.openPanel(panelId);
                    });
                }
            });
        this.panels.forEach(panel => {
            panel
                .querySelectorAll('[data-panel-opens]')
                .forEach(panelOpener => {
                    let panelId = panelOpener.dataset.panelOpens;
                    if (
                        this.panels
                            .map(panel => panel.dataset.panel)
                            .includes(panelId)
                    ) {
                        panelOpener.addEventListener('click', e => {
                            e.preventDefault();
                            this.openPanel(panelId);
                        });
                    }
                });

            panel
                .querySelectorAll('button.closePanel')
                .forEach(button =>
                    button.addEventListener(
                        'click',
                        this.closePanels.bind(this)
                    )
                );
        });

        // this.swipeDetector = new TinyGesture(this.mobileMenu);
        // this.swipeDetector.on('swiperight', e => {

        // });

        window.addEventListener('keydown', e => {
            if (
                e.key &&
                e.key.substr(0, 3).toLowerCase() == 'esc' &&
                document.documentElement.classList.contains('menuPanelOpened')
            ) {
                this.closePanels();
            }
        });

        this.initFloatingPanels();
    }

    initFloatingPanels() {
        const floatingPanels = this.panels.filter(panel =>
            panel.classList.contains('floatingPanel')
        );

        if (floatingPanels.length) {
            floatingPanels.forEach(fPanel => {
                // Find a matching 'data-panel-float'
                const trigger = this.header.querySelector(
                    `[data-panel-float="${fPanel.dataset.panel}"]`
                );
                if (trigger) {
                    // Start tracking stuff
                    const handleResize = () => {
                        // Find x position of trigger, add it as CSS variable to the floating panel
                        const x = trigger.getBoundingClientRect().left;
                        const y = trigger.getBoundingClientRect().bottom;
                        fPanel.style.setProperty('--x', `${x}px`);
                        fPanel.style.setProperty('--y', `${y}px`);
                    };
                    // app.registerResizeEvent(handleResize);
                    handleResize();
					setTimeout(handleResize.bind(this), 500);
                    const resizeObserver = new ResizeObserver(() => {
                        handleResize();
                    });
                    resizeObserver.observe(document.body);
                }
            });
        }
    }

    openPanel(panelId) {
        console.log(`Opening panel ${panelId}`);

        const activatePanel = panel => {
            panel.classList.add('active');
            panel.classList.add('replacer');
            document.documentElement.classList.add('disableScrollMenu');
            this.panelContainer.classList.add('panelActive');
			if (panel.classList.contains('floatingPanel')) {
				this.panelContainer.classList.add('isFloatingPanel');
			} else {
				document.documentElement.classList.add('menuPanelOpened');
			}
            this.updateBackgroundSize(panel);
        };
        this.panels.forEach(panel => {
            panel.querySelectorAll('[data-panel-opens]').forEach(link => {
                link.classList.remove('panelActive');
            });
            panel
                .querySelectorAll(`[data-panel-opens="${panelId}"]`)
                .forEach(link => {
                    link.classList.add('panelActive');
                });
        });

        this.header.querySelectorAll('[data-panel-opens]').forEach(link => {
            link.classList.remove('panelActive');
        });
        this.header
            .querySelectorAll(`[data-panel-opens="${panelId}"]`)
            .forEach(link => {
                link.classList.add('panelActive');
            });
        let panel = this.panels.filter(
            panel => panel.dataset.panel == panelId
        )[0];
        // debugger;
        if (panel) {
            if (!panel.classList.contains('active')) {
                // Open panel
                let activePanels = this.panels.filter(panel =>
                    panel.classList.contains('active')
                );
                if (activePanels.length) {
                    activePanels.forEach(panel => {
                        panel.classList.add('replacing');
                        panel.classList.remove('active');
                        panel.classList.remove('replacer');

                        setTimeout(() => {
                            panel.classList.remove('replacing');
                        }, app.animationDuration);
                    });
                    activatePanel(panel);
                    this.videos.forEach(video => {
                        video.pause();
                    });
                } else {
                    activatePanel(panel);
                    this.videos.forEach(video => {
                        video.pause();
                    });
                }
            } else {
                // Close panel
                panel.classList.remove('active');
                document.documentElement.classList.remove('menuPanelOpened');
                document.documentElement.classList.remove('disableScrollMenu');
                panel.classList.remove('replacer');

                this.panels.forEach(panel => {
                    panel.querySelectorAll('.panelActive').forEach(link => {
                        link.classList.remove('panelActive');
                    });
                });

                this.header.querySelectorAll('.panelActive').forEach(link => {
                    link.classList.remove('panelActive');
                });
                this.panelContainer.classList.remove('panelActive');

                //WEB-796 video pauzeren bij openen menu panel
                this.videos.forEach(video => {
                    video.play();
                });
            }
        }
    }

    closePanels() {
        this.panels.forEach(panel => {
            panel.classList.remove('replacing');
            panel.classList.remove('replacer');
            panel.classList.remove('active');
        });
        document.documentElement.classList.remove('menuPanelOpened');
        document.documentElement.classList.remove('disableScrollMenu');

        this.panels.forEach(panel => {
            panel.querySelectorAll('.panelActive').forEach(link => {
                link.classList.remove('panelActive');
            });
        });

        this.header.querySelectorAll('.panelActive').forEach(link => {
            link.classList.remove('panelActive');
        });

        this.panelContainer.classList.remove('panelActive');
        this.panelContainer.classList.remove('isFloatingPanel');

        //WEB-796 video hervatten bij sluiten menu panel
        this.videos.forEach(video => {
            video.play();
        });
    }

    updateBackgroundSize(panel) {
        // Get highest panel's height
        let height = 0;
        if (panel) {
            height = panel.clientHeight;
        } else {
            height = Math.max(...this.panels.map(panel => panel.clientHeight));
        }
        this.panelContainer.style.setProperty(
            '--panelHeight',
            `${height - this.header.clientHeight}px`
        );
    }

    resize() {
        this.previousScroll = window.pageYOffset;
        this.threshold = this.header.clientHeight;

        this.scroll();
    }

    scroll() {
        // First, decide if it's up or down
        if (this.previousScroll > window.pageYOffset) {
            // Up
            if (this.scrollCounter > 0) {
                this.scrollCounter = 0;
            }

            this.scrollCounter += window.pageYOffset - this.previousScroll;
        } else if (this.previousScroll < window.pageYOffset) {
            // Down
            if (this.scrollCounter < 0) {
                this.scrollCounter = 0;
            }

            this.scrollCounter += window.pageYOffset - this.previousScroll;
        }

        if (!document.documentElement.classList.contains('mobileMenuActive')) {
            if (window.pageYOffset < this.threshold) {
                this.show();
            } else {
                if (this.scrollCounter > this.threshold) {
                    this.hide();
                }

                if (this.scrollCounter < this.threshold * -1) {
                    this.show();
                }
            }
        }

        this.previousScroll = window.pageYOffset;
    }

    hide() {
        this.header.classList.add('hidden');
    }

    show() {
        this.header.classList.remove('hidden');
    }
}
