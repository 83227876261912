import CustomHTMLElement from './customHTMLElement';

export default class SearchSuggestion extends CustomHTMLElement {
    /**
     * @param {Object} properties 
     * @param {String} properties.url
     * @param {String} properties.name
     */
	constructor(properties = {}) {
		super({
            url: '',
            title: '',
            name: '',
		});
		this.setProperties(properties);
        this._createPropertyMethods();

		this._template = require('./templates/searchSuggestion.handlebars');
    }
}