export default class GTMController {
    constructor() {
        this.type = this.decideType();
        this.data = this.getData();
        window.dataLayer = window.dataLayer || [];

        this.dataReady();
    }

    // Shortcut to getting the dataLayer
    get dl() {
        return window.dataLayer;
    }

    decideType() {
        // Assume it's universal
        let type = 'universal';

        // Try to determine type from URL
        let pathname = window.location.pathname.toLowerCase() || '';
        switch (pathname.split('/')[1]) {
            case 'werknemers':
                type = 'employee';
                break;
            case 'werkgevers':
                type = 'employer';
                break;
        }

        return type;
    }

    getData() {
        let data = {};
        if (document.querySelector('.gtmInfo')) {
            const info = document.querySelector('.gtmInfo');
            data = Object.assign({}, info.dataset);

            // Manual overrides
            data.pageType = info.dataset.pageType ? info.dataset.pageType.replace('STL.', '') : undefined;
        }

        // We collect all info from the GTMinfo element as data to use in here
        return data;
    }

    // Generic calls

    dataReady() {
        // As per MaxLead's instructions, clear the page object on page navigation.
        this.dl.push({page: null});

        const data = {
            event: `data_ready`,
            user: {
                user_id: undefined,
                user_type: undefined,
                user_logged_in: "0",
            },
            page: {
                page_id: this.data.pageId,
                page_type: this.data.pageType,
                page_label_1: this.data.pageLabel1 || this.type, // Depends on audience
                page_label_2: this.data.pageLabel2, // Whatever relevant, depends on topic
                page_location: location.href || location.host || undefined, // only used for virtual pageviews
                page_variant: this.data.pageVariant,
                page_country: this.data.pageCountry || 'nl', // Assuming Dutch
                page_language: this.data.pageLanguage || 'nl-nl', // Assuming Dutch
            },
            technology: {
                platform: 'web',
                ip_address: this.data.technologyIp || undefined,
            }
        };

        this.dl.push(data);
        this.debugLog(data, `dataReady`);
    }

    // End of generic calls

    // Decision tree calls
    // Calls are handled by the DecisionTree objects (app.decisionTreeController.instances)
    
    /**
     * @param {String} location Panel title
     */
    decisionTreeOpen(location) {
        const data = {
            event: 'begin_choice_helper',
            component_type: `choice helper ${this.type}`,
            component_location: location, 
            component_category: 'choice' // Due to the first panel always being a choice and not a result
        };

        this.dl.push(data);
        this.debugLog(data, 'Decision Tree');
    }

    /**
     * @param {String} location Panel title where action took place
     * @param {String} category Either 'navigation' or 'no results', depends if user navigates or used an option from a no results panel
     * @param {String} componentName Title of selected option, action, or contact option from no results panel
     */
    decisionTreeInteraction(location, category, componentName) {
        const data = {
            event: 'choice_helper_interaction',
            component_type: `choice helper ${this.type}`,
            component_location: location,
            component_category: category,
            component_name: componentName.substring(0, 100),
        };

        this.dl.push(data);
        this.debugLog(data, 'Decision Tree');
    }

    // End of decision tree calls

    // Quiz calls
    // Calls are handled by the quiz objects (app.quizController.instances)

    /**
     * 
     * @param {String} location  Panel title
     */
    quizOpen(location, type = 'quiz') {
        const data = {
            event: `begin_${type}`,
            component_type: `${type} ${this.type}`,
            component_location: location,
            component_category: 'navigation',
        };
        
        this.dl.push(data);
        this.debugLog(data, `Quiz`);
    }

    /**
     * 
     * @param {String} location Panel title where action took place
     * @param {String} category Either 'navigation' or 'results' ('navigation' when selecting an answer, 'results' when selecting something from the results panel)
     * @param {String} componentName Title of selected option or action
     */
    quizInteraction(location, category, componentName, type = 'quiz') {
        const data = {
            event: `${type}_interaction`,
            component_type: `${type} ${this.type}`,
            component_location: location,
            component_category: category,
            component_name: componentName.substring(0, 100),
        };

        this.dl.push(data);
        this.debugLog(data, `Quiz`);
    }

    /**
     * 
     * @param {String} location Panel title where action took place
     * @param {String} category Either 'navigation' or 'results' ('navigation' when at an answer, 'results' when at the results panel)
     */
    quizClose(location, category, type = 'quiz') {
        const data = {
            event: `end_${type}`,
            component_type: `${type} ${this.type}`,
            component_location: location,
            component_category: category,
        };

        this.dl.push(data);
        this.debugLog(data, `Quiz`);
    }

    quizResults(location, score, type = 'quiz') {
        const data = {
            event: `results_${type}`,
            component_type: `${type} ${this.type}`,
            component_location: location,
            component_category: 'results',
            component_data: score
        };

        this.dl.push(data);
        this.debugLog(data, `Quiz`);
    }

    // End of quiz calls

    // FAQ calls
    // Calls are handled by the accordion object which determines if it's an FAQ item or not

    /**
     * @param {String} question 
     * @param {String} category Category FAQ is in. Generic if undetermined
     */
    faqSelect(question, category = 'generic') {
        const data = {
            event: 'select_faq',
            component_type: 'faq',
            component_category: category,
            component_name: question.substring(0, 100),
        };

        this.dl.push(data);
        this.debugLog(data, 'FAQ select');
    }

    // End of FAQ calls

    // Headersearch calls
    // Calls are handled by the HeaderSearch controller

    headerSearchClick(query, link) {
        const data = {
            event: 'header_search_click',
            component_type: 'search',
            search_query: query,
            search_link: link
        };

        this.dl.push(data);
        this.debugLog(data, 'Headersearch suggestion click');
    }

    // End of headersearch calls

    debugLog(data, title = null) {
        return;
        console.log(`------ ${title ? title : 'GTM Data'} ------`);
        console.log(data);
    }
}