import SearchSuggestion from '../elements/searchSuggestion';
import delegate from 'delegate';
// import Mark from 'mark.js';

export default class HeaderSearchController {
    constructor() {
        this.formContainer = null;
        this.form = null;
        this.formInput = null;
        this.searchResultsContainer = null;
        this.mobileSearchbutton = null;
        this.apiEndpoint = '/api/search/find?query=';
        this.apiContext;

        this.abortController = null; // Will contain an AbortController when making a new API call
        this.waiterTime = 250; // Time in MS to wait with making a new API call
        this.changeWaiter = null; // Clearable timeout handler
        this.minInput = 3; // Minimal amount of characters before a search is triggered

        this.originalSearchSuggestions = [];
        this.originalSearchTitle = '';

        this.videos = [];

        // this.mark = null;

        if (document.querySelector('.headerSearchContainer')) {
            this.init();
        }
    }

    init() {
        this.formContainer = document.querySelector('.headerSearchContainer');
        this.form = this.formContainer.querySelector('form');
        this.formInput = this.form.querySelector('input[name="q"]');
        if (this.formInput.dataset.context) {
            this.apiContext = this.formInput.dataset.context;
        }
        this.searchResultsContainer = this.formContainer;

        this.videos = document.querySelectorAll('video');
        
        // this.mark = new Mark(this.searchResultsContainer.querySelector('ul'));
        
        if ('AbortController' in window) {
            this.abortController = new AbortController();
        }

        // Make a copy of the search suggestions for restore ability
        this.searchResultsContainer.querySelectorAll('ul li').forEach(li => this.originalSearchSuggestions.push(li.cloneNode(true)));

        // Copy original title
        this.originalSearchTitle = this.searchResultsContainer.querySelector('#searchResultsTitle').innerText;

        this.setEventListeners();
        this.formContainer.removeAttribute("style");
    }

    setEventListeners() {
        // Opening
        this.formInput.addEventListener('focus', this.showSearchResults.bind(this));
        this.formInput.addEventListener('click', this.showSearchResults.bind(this));
        if (document.querySelector('.headerMenu .search')) {
            document.querySelector('.headerMenu .search').addEventListener('click', () => {
                if (document.querySelector('body > header').classList.contains('searchActive')) {
                    this.hideSearchResults();
                } else {
                    this.showSearchResults();
                    this.formInput.focus();
                }
            });
        }

        if(document.querySelector('#hero-search')) {
            document.querySelector('#hero-search').addEventListener('click', (e) => {
                e.preventDefault();
                
                if (document.querySelector('body > header').classList.contains('searchActive')) {
                    this.hideSearchResults();
                } else {
                    this.showSearchResults();
                    this.formInput.focus();
                }
            });
        }

        // Changing
        this.formInput.addEventListener('input', this.handleInputChange.bind(this));

        // Submitting
        this.searchResultsContainer.querySelector('a.fullSearch').addEventListener('click', () => this.form.submit());
        this.form.addEventListener('submit', e => {
            console.log('Submit');
            if (!this.formInput.value.trim()) {
                e.preventDefault();
                this.formInput.focus();
                return false;
            }
            return true;
        });

        // Resetting
        this.form.addEventListener('reset', e => {
            console.log('Reset');
            this.resetResults();
            this.formInput.focus();
        });

        // Closing
        this.searchResultsContainer.querySelectorAll('.closeButton').forEach(closeButton => {
            closeButton.addEventListener('click', e => {
                this.hideSearchResults();
                this.formInput.blur();
            })
        });

        this.searchResultsContainer.querySelectorAll('.close, .closeSearch').forEach(button => {
            button.addEventListener('click', e => {
                this.hideSearchResults();
                this.formInput.blur();
            });
        });

        document.addEventListener('keydown', e => {
			if (e.key.toLowerCase().substr(0, 3) == 'esc') {
                this.hideSearchResults();
                this.formInput.blur();
            }
        });

        this.formContainer.addEventListener('click', e => {
            // Should only trigger if clicked outside the panel
            if (e.target.contains(this.formContainer.querySelector('.panelWrapper'))) {
                this.hideSearchResults();
                this.formInput.blur();
            }
        });

        // Others
        const focusButton = this.form.querySelector('button.focus');
        if (focusButton) {
            focusButton.addEventListener('click', () => this.formInput.focus());
        }

        const clearButton = this.form.querySelector('button[type="reset"]');
        if (clearButton) {
            clearButton.addEventListener('click', () => {
                this.resetResults();
                this.formInput.focus();

                if (focusButton) {
                    clearButton.setAttribute('disabled', 'disabled');
                    focusButton.setAttribute('disabled', 'disabled');
                }
            });
        }

        this.formInput.addEventListener('keydown', e => {
            if (e.key.toLowerCase() == 'arrowdown') {
                e.preventDefault();
                this.searchResultsContainer.querySelector('ul li a').focus();
            }
        });

        delegate('.headerSearchContainer ul li a', 'keydown', e => {
            if (e.key.toLowerCase() == 'arrowup') {
                e.preventDefault();
                this.arrowUp(e.delegateTarget.parentNode);
            } else if (e.key.toLowerCase() == 'arrowdown') {
                e.preventDefault();
                this.arrowDown(e.delegateTarget.parentNode);
            }
        });

        delegate('.headerSearchContainer a.fullSearch', 'keydown', e => {
            if (e.key.toLowerCase() == 'arrowup') {
                e.preventDefault();
                this.arrowUp(e.delegateTarget);
            } else if (e.key.toLowerCase() == 'arrowdown') {
                e.preventDefault();
                this.arrowDown(e.delegateTarget);
            }
        });

        // GTM
        delegate('.headerSearchContainer ul li a', 'click', e => {
            const query = this.formInput.value.trim();
            if (query) {
                let a = e.delegateTarget;
                app.GTM.headerSearchClick(query, a.href);
            }
        });

        // Searchpage specific
        if (document.querySelector('section.searchFilters')) {
            const form = document.querySelector('section.searchFilters form');

            const selectricEvents = () => {
                form.querySelectorAll('select').forEach(select => {
                    ['change', 'selectric-change'].forEach(eventName => {
                        $(select).on(eventName, e => {
                            form.submit();
                        });
                    });
                });
            }

            if (window.$ && $.fn.selectric) {
                selectricEvents();
            } else {
                app.selectricCBs.push(() => {
                    selectricEvents();
                });
            }

            form.querySelectorAll('.audienceTabs .tabHeader button').forEach(button => {
                button.addEventListener('click', e => {
                    e.preventDefault();

                    let select = form.querySelector(`select[data-name="audience"]`);
                    select.querySelectorAll('option').forEach((option, index) => {
                        if (option.value == button.value) {
                            $(select).prop('selectedIndex', index).selectric('refresh');
                            form.submit();
                        }
                    });
                });
            });
        }

        const infoGuideMobileSearch = document.querySelector('.panel.floatingPanel span.infoGuideMobileSearch');
        const infoGuideMobileClose = document.querySelector('.panel.floatingPanel button.closePanel.mobile');
        if(infoGuideMobileSearch && infoGuideMobileClose){
            console.log('Set mobile clicker');
            infoGuideMobileSearch.addEventListener('click', () => {
                infoGuideMobileClose.click(); 
                document.querySelector('.headerMenu .search').click();
            });   
                  
        }
    }

    /**
     * @param {HTMLElement} li 
     */
    arrowDown(el) {
        console.log(el);
        if (el.nextElementSibling && el.nextElementSibling.querySelector('a')) {
            el.nextElementSibling.querySelector('a').focus();
        } else if (!this.searchResultsContainer.querySelector('a.fullSearch').classList.contains('inactive')) {
            this.searchResultsContainer.querySelector('a.fullSearch').focus();
        } else if (el == this.searchResultsContainer.querySelector('a.fullSearch')) {
            this.formInput.focus();
        }
    }

    /**
     * @param {HTMLElement} li 
     */
    arrowUp(el) {
        console.log(el);
        if (el.previousElementSibling && el.previousElementSibling.querySelector('a')) {
            el.previousElementSibling.querySelector('a').focus();
        } else if (el == this.formInput && !this.searchResultsContainer.querySelector('a.fullSearch').classList.contains('inactive')) {
            this.searchResultsContainer.querySelector('a.fullSearch').focus();
        } else if (el == this.searchResultsContainer.querySelector('a.fullSearch')) {
            this.searchResultsContainer.querySelector('ul li:last-of-type a').focus();
        } else {
            this.formInput.focus();
        }
    }

    handleInputChange() {
        let query = this.formInput.value.trim();
        const clearButton = this.form.querySelector('button[type="reset"]');
        const focusButton = this.form.querySelector('button.focus');

        if (query && query.length >= this.minInput) {
            // Update status button to show
            this.updateResults(query);
            focusButton.removeAttribute('disabled');
            clearButton.removeAttribute('disabled');
        } 
        
        if (query) {
            // User entered less than 3 characters
            focusButton.removeAttribute('disabled');
            clearButton.removeAttribute('disabled');
        } else {
            focusButton.setAttribute('disabled', 'disabled');
            clearButton.setAttribute('disabled', 'disabled');
            this.resetResults();
        }
    }

    showSearchResults() {
        document.querySelector('body > header').classList.add('searchActive');
        this.searchResultsContainer.classList.add('active');
        
        this.searchResultsContainer.setAttribute('aria-expanded', true);
        this.searchResultsContainer.setAttribute('aria-hidden', false);
        
        document.documentElement.classList.add('blurSearch');
        document.documentElement.classList.add('disableScroll');

        //WEB-796 video pauzeren bij openen menu panel
		this.videos.forEach(video => {
			video.pause();
		});

        // Enable tabbing on all items
        this.searchResultsContainer.querySelectorAll('a, button, input').forEach(clickable => {
            clickable.setAttribute('tabindex', '0');
            clickable.setAttribute('aria-hidden', 'false');
        });
    }

    hideSearchResults() {
        document.querySelector('body > header').classList.remove('searchActive');
        this.searchResultsContainer.classList.remove('active');

        this.searchResultsContainer.setAttribute('aria-expanded', false);
        this.searchResultsContainer.setAttribute('aria-hidden', true);

        document.documentElement.classList.remove('blurSearch');
        document.documentElement.classList.remove('disableScroll');

        setTimeout(() => {
            if (!this.searchResultsContainer.classList.contains('active')) {
                this.resetResults();
            }
        }, 400);

        //WEB-796 video pauzeren bij openen menu panel
		this.videos.forEach(video => {
			video.play();
		});

        // Disable tabbing on all items
        this.searchResultsContainer.querySelectorAll('a, button, input').forEach(clickable => {
            clickable.setAttribute('tabindex', '-1');
            clickable.setAttribute('aria-hidden', 'true');
        });
    }

    updateResults(query) {
        // Reset waiter to prevent unnessecary calls
        if (this.changeWaiter !== null) {
            clearTimeout(this.changeWaiter);
        }

        this.changeWaiter = setTimeout(() => {
            this.searchResultsContainer.querySelector('ul').classList.add('loading');

            // If there's a call going on, abort it and create a new abortcontroller to reuse
            if ('AbortController' in window && this.abortController) {
                this.abortController.abort();
                this.abortController = new AbortController();
            }

            // Fetch new results
            this.fetchResults(query).then(data => {
                this.insertSearchSuggestions(data, query);
            }).catch(e => {
                console.error(e);
            }).finally(() => {
                this.searchResultsContainer.querySelector('ul').classList.remove('loading');
            });
            
            this.changeWaiter = null;
        }, this.waiterTime);
    }

    insertSearchSuggestions(data, query = null) {
        // Data should be an array containing nothing but keywords. So create listitems that will search for that
        let suggestions = [];
        if (data.results) {
            data.results.forEach(item => {
                let suggestion = new SearchSuggestion({
                    name: item.highlightedTitle || item.title || item.url,
                    title: item.title || item.highlightedTitle || item.url,
                    url: item.url
                });
    
                suggestions.push(suggestion.generate());
            });
        }        

        // Clear current suggestion list
        this.searchResultsContainer.querySelector('ul').innerHTML = '';

        // Add class
        this.searchResultsContainer.querySelector('ul').classList.add('searchResults');
        
        // Insert the newly made suggestions
        suggestions.forEach(suggestion => this.searchResultsContainer.querySelector('ul').appendChild(suggestion));

        // Highlight text matching with query
        // if (query) {
        //     this.mark.mark(query);
        // }

        // Hide title
        this.searchResultsContainer.querySelector('.status').classList.add('d-none');

        // Update fullSearch button
        if (data.totalItems) {
            this.searchResultsContainer.querySelector('a.fullSearch').classList.remove('inactive');

            if (data.totalItems > 1) {
                this.searchResultsContainer.querySelector('a.fullSearch span').innerHTML = `Bekijk alle ${data.totalItems} resultaten`;
            } else {
                this.searchResultsContainer.querySelector('a.fullSearch span').innerHTML = `Bekijk resultaat`;
            }
        } else {
            this.searchResultsContainer.querySelector('a.fullSearch').classList.add('inactive');
            this.searchResultsContainer.querySelector('.status').classList.remove('d-none');
        }

        // Update button text
        if (data.totalItems > 1) {
            this.searchResultsContainer.querySelector('a.fullSearch span').innerHTML = `Bekijk alle ${data.totalItems} resultaten`;
        } else {
            this.searchResultsContainer.querySelector('a.fullSearch span').innerHTML = `Bekijk alle resultaten`;
        }

        // Update title
        this.searchResultsContainer.querySelector('#searchResultsTitle').innerHTML = `${data.totalItems == 0 ? 'Geen' : data.totalItems} ${data.totalItems == 1 ? 'resultaat' : 'resultaten'} gevonden`;
    }

    resetResults() {
        // Cancel any ongoing call
        if ('AbortController' in window && this.abortController) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }

        // Clear input
        this.formInput.value = '';

        // Clear suggestion list
        this.searchResultsContainer.querySelector('ul').innerHTML = '';

        // Reset class
        this.searchResultsContainer.querySelector('ul').classList.remove('searchResults');
        
        // Insert backed up suggestions and title
        this.originalSearchSuggestions.forEach(suggestion => this.searchResultsContainer.querySelector('ul').appendChild(suggestion));
        this.searchResultsContainer.querySelector('#searchResultsTitle').innerHTML = this.originalSearchTitle;
        this.searchResultsContainer.querySelector('.status').classList.remove('d-none');
        this.searchResultsContainer.querySelector('ul').classList.remove('loading');
        this.searchResultsContainer.querySelector('a.fullSearch').classList.add('inactive');
    }

    fetchResults(query) {
        let options = {};
        if ('AbortController' in window && this.abortController) {
            options.signal = this.abortController.signal;
        }

        let url = `${this.apiEndpoint}${encodeURIComponent(query)}&pageSize=5`;
        if (this.apiContext) {
            url += `&context=${this.apiContext}`;
        }

        return fetch(url, options).then(response => {
            return response.json();
        });
    }

    newPage() {
        this.form = null;
        this.formInput = null;
        this.searchResultsContainer = null;

        this.abortController = null; // Will contain an AbortController when making a new API call
        this.changeWaiter = null; // Clearable timeout handler

        this.originalSearchSuggestions = [];
        this.originalSearchTitle = '';

        this.mark = null;

        if (document.querySelector('form.headerSearchForm')) {
            this.init();
        }
    }
}