export default class Share {
    constructor(container) {
        this.container = container;

        this.url = null;
        this.description = null;
        this.title = null;
        this.tagTitle = 'Delen via MEDIA';
        this.mobile = false;

        this.nativeShare = false;

        this.order = [
            'twitter',
            'linkedin',
            'facebook',
            'email',
            'whatsapp',
            'native',
        ];

        this.medias = {
            linkedin: {
                name: 'LinkedIn',
                enabled: true,
                icon: '<div class="icon"><svg class="sprite icon_social_linkedin"><use xlink:href="#icon_social_linkedin" /></svg></div><span>LinkedIn</span>',
                mobileOnly: false,
            },
            facebook: {
                name: 'Facebook',
                enabled: true,
                icon: '<div class="icon"><svg class="sprite icon_social_facebook"><use xlink:href="#icon_social_facebook" /></svg></div><span>Facebook</span>',
                mobileOnly: false,
            },
            twitter: {
                name: 'X',
                enabled: true,
                icon: `<div class="icon"><svg class="sprite icon_social_twitter"><use xlink:href="#icon_social_twitter" /></svg></div><span>X</span>`,
                mobileOnly: false,
            },
            whatsapp: {
                name: 'WhatsApp',
                enabled: true,
                icon: '<div class="icon"><svg class="sprite icon_contact_whatsapp"><use xlink:href="#icon_contact_whatsapp" /></svg></div><span>Whatsapp</span>',
                mobileOnly: false,
            },
            email: {
                name: 'e-mail',
                enabled: true,
                icon: '<div class="icon"><svg class="sprite icon_contact_email"><use xlink:href="#icon_contact_email" /></svg></div><span>E-mail</span>',
                mobileOnly: false,
            },
            native: {
                name: 'Share',
                enabled: false,
                tagTitle: 'Delen',
                icon: '<svg class="sprite icon_share_generic"><use xlink:href="#icon_share_generic" /></svg>',
                mobileOnly: false,
            },
        };
    }

    init() {
        this.mobile = this.detectMobile();
        this.url = this.obtainUrl();
        this.description = this.obtainDescription();
        this.title = this.obtainTitle();

        this.createShareButtons();
    }

    createShareButtons() {
        let list = Object.keys(this.medias);

        if (this.order) {
            list = this.order;
        }
        list.forEach(media => {
            if (this.medias[media].enabled) {
                let render = true;
                if (this.medias[media].mobileOnly && !this.mobile) {
                    render = false;
                }

                if (media == 'native' && !navigator.share) {
                    render = false;
                }

                console.log(`${media}: ${render}`);

                if (render) {
                    const tag = document.createElement('a');
                    tag.setAttribute('target', '_blank');
                    tag.setAttribute('rel', 'noopener');
                    tag.href = this[media]();
                    if (this.medias[media].tagTitle) {
                        tag.title = this.medias[media].tagTitle;
                    } else {
                        tag.title = this.tagTitle.replace(/MEDIA/g, this.medias[media].name);
                    }
                    tag.innerHTML = this.medias[media].icon;

                    this.bindEvents(tag, media);
                    this.container.appendChild(tag);
                }
            }
        });
    }

    detectMobile() {
        return /iPhone|Android|Blackberry/i.test(navigator.userAgent);
    }

    obtainUrl() {
        if (this.container.dataset.url) {
            return this.container.dataset.url;
        }

        return window.location.href;
    }

    obtainDescription() {
        if (this.container.dataset.description) {
            return this.container.dataset.description;
        }
        
        let metaDescription = null;
        let ogDescription = null;
        let metaTags = document.head.getElementsByTagName('meta');

        for (let metaIndex = 0; metaIndex < metaTags.length; metaIndex++) {
            if (metaTags[metaIndex].getAttribute('property') === 'description') {
                metaDescription = metaTags[metaIndex].getAttribute('content');
            }
            if (metaTags[metaIndex].getAttribute('property') === 'og:description') {
                ogDescription = metaTags[metaIndex].getAttribute('content');
            }
        }

        if (metaDescription === null && ogDescription === null) {
            return '';
        } 
        
        if (ogDescription !== null) {
            return ogDescription;
        }

        return metaDescription;
    }

    obtainTitle() {
        if (this.container.dataset.title) {
            return this.container.dataset.title;
        }

        return document.title;
    }

    obtainTagTitle(media) {
        if (this.container.dataset.tagTitle) {
            return this.container.dataset.tagTitle.replace(`MEDIA`, this.medias[media].name);
        }

        return `Deel via ${this.medias[media].name}`;
    }

    // Social media specific methods
    twitter() {
        return `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.url)}&text=${encodeURIComponent(this.description)}`;
    }

    linkedin() {
        return `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(this.url)}&mini=true&summary=${encodeURIComponent(this.description)}`
    }
    
    facebook() {
        return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(this.url)}`;
    }
    
    email() {
        return `mailto:?body=${encodeURIComponent(this.url) + ' ' + this.description}&subject=Ik heb een interessante pagina gevonden: ${this.title}`;
    }

    whatsapp() {
        return `https://wa.me?text=${encodeURIComponent(this.url)}`;
    }

    native() {
        return 'javascript:void(0);';
    }

    bindEvents(tag, media) {
        tag.addEventListener('click', e => {
            e.preventDefault();

            if (media == 'native') {
                const data = {
                    title: this.title,
                    description: this.description,
                    url: this.url
                };

                try {
                    navigator.share(data);
                } catch (e) {
                    if (console && console.error) {
                        console.error(e);
                    }
                }
            } else {
                window.open(tag.href, 'NewWindow', `menubar=no,toolbar=no,status=no,width=570,height=570,top=${(screen.height - 570) / 2},left=${(screen.width - 570) / 2}`);
            }
        });
    }
}