export default class BaseApi {
    constructor() {
        this._baseUrl = null;

        this.methods = {
            post: 'POST',
            get: 'GET',
            put: 'PUT',
            patch: 'PATCH',
            delete: 'DELETE',
        };

        // Methods that can contain a body
        this._bodyMethods = [
            this.methods.post,
            this.methods.put,
        ];

        this._headers = {};
    }

    set url(url) {
        this._baseUrl = url;
    }
    
    get url() {
        return this._baseUrl;
    }
    
    fetch(url, method = this.methods.get, data = {}, headers = {}, properties = {}) {
        if (this._baseUrl === null) {
            throw Error(`No API URL specified`);
        }

        let urlToCall = this._baseUrl + url;

        try {
            urlToCall = new URL(urlToCall);
        } catch(e) {
            // No need to do anything, probably an URL without hostname   
        }

        let newHeaders = Object.assign({}, this._headers);

        const fetchProperties = Object.assign({
            mode: 'cors',
            referrer: 'no-referrer',
            headers: newHeaders,
            method: method,
        }, properties);

        if (Object.keys(headers).length > 0) {
            Object.keys(headers).forEach(header => {
                fetchProperties.headers[header] = headers[header];
            });
        }

        if (this._bodyMethods.includes(method)) {
            // let formData = new FormData();
            // Object.keys(data).forEach(dataKey => {
                // formData.append(dataKey, data[dataKey]);
            // });
            // fetchProperties.headers['Content-Type'] = 'multipart/form-data';
            fetchProperties.body = JSON.stringify(data);
        } else {
            if (urlToCall instanceof URL) {
                Object.keys(data).forEach(dataKey => urlToCall.searchParams.append(dataKey, data[dataKey]));
            } else {
                urlToCall += '?' + (new URLSearchParams(data)).toString();
            }
        }

        return fetch(urlToCall, fetchProperties).then(response => {
            if (!response.ok) {
                throw new Error(response);
            }

            return response.json();
        });
    }

    test(data, url = false) {
        if (url) {
            console.log(`${this._baseUrl}${url}`);
        }
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(data);
            }, window.app.random(2000, 8000));
        });
    }
}