import FaqSearchSuggestion from '../elements/faqSearchSuggestion';
import delegate from 'delegate';

export default class faqSearchController {
    constructor() {
        this.formContainer = null;
        this.form = null;
        this.formInput = null;
        this.searchResultsContainer = null;
        this.allSubjects = null;
        this.totalSubjects = 0;
        this.apiEndpoint = '/api/autocomplete/faq';

        this.abortController = null; // Will contain an AbortController when making a new API call
        this.waiterTime = 250; // Time in MS to wait with making a new API call
        this.changeWaiter = null; // Clearable timeout handler
        this.minInput = 3; // Minimal amount of characters before a search is triggered

        this.originalSearchSuggestions = [];
        // this.originalSearchTitle = '';

        this.mark = null;

        if (document.querySelector('.faqSearch')) {
            this.getData();
            this.init();
        }
    }

    init() {
        this.formContainer = document.querySelector('.faqSearch');
        this.form = this.formContainer.querySelector('form');
        this.formInput = this.form.querySelector('input[name="q"]');
        this.searchResultsContainer = this.formContainer.querySelector('.searchResultsContainer');
                
        if ('AbortController' in window) {
            this.abortController = new AbortController();
        }

        // Make a copy of the search suggestions for restore ability
        this.searchResultsContainer.querySelectorAll('ul li').forEach(li => this.originalSearchSuggestions.push(li.cloneNode(true)));

        this.setEventListeners();
        this.formContainer.removeAttribute("style");
    }

    setEventListeners() {
        // Opening
        this.formInput.addEventListener('focus', this.showSearchResults.bind(this));
        this.formInput.addEventListener('click', this.showSearchResults.bind(this));

        // Changing
        this.formInput.addEventListener('input', this.handleInputChange.bind(this));

        // Submitting
        this.searchResultsContainer.querySelector('a.fullSearch').addEventListener('click', () => this.form.submit());
        this.form.addEventListener('submit', e => {
            if (!this.formInput.value.trim()) {
                e.preventDefault();
                this.formInput.focus();
                return false;
            }
            return true;
        });

        // Resetting
        this.form.addEventListener('reset', e => {
            this.resetResults();
            this.formInput.focus();
        });

        // Others
        const focusButton = this.form.querySelector('button.focus');
        if (focusButton) {
            focusButton.addEventListener('click', () => this.formInput.focus());
        }

        const clearButton = this.form.querySelector('button[type="reset"]');
        if (clearButton) {
            clearButton.addEventListener('click', () => {
                this.resetResults();
                this.formInput.focus();

                if (focusButton) {
                    clearButton.setAttribute('disabled', 'disabled');
                    focusButton.setAttribute('disabled', 'disabled');
                }
            });
        } 

        this.formInput.addEventListener('keydown', e => {
            if (e.key.toLowerCase() == 'arrowdown') {
                e.preventDefault();
                this.searchResultsContainer.querySelector('ul li a').focus();
            }
        });

        delegate('.faqSearch ul li a', 'keydown', e => {
            if (e.key.toLowerCase() == 'arrowup') {
                e.preventDefault();
                this.arrowUp(e.delegateTarget.parentNode);
            } else if (e.key.toLowerCase() == 'arrowdown') {
                e.preventDefault();
                this.arrowDown(e.delegateTarget.parentNode);
            }
        });

        delegate('.faqSearch a.fullSearch', 'keydown', e => {
            if (e.key.toLowerCase() == 'arrowup') {
                e.preventDefault();
                this.arrowUp(e.delegateTarget);
            } else if (e.key.toLowerCase() == 'arrowdown') {
                e.preventDefault();
                this.arrowDown(e.delegateTarget);
            }
        });
    }

    /**
     * @param {HTMLElement} li 
     */
    arrowDown(el) {
        console.log(el);
        if (el.nextElementSibling && el.nextElementSibling.querySelector('a')) {
            el.nextElementSibling.querySelector('a').focus();
        } else if (!this.searchResultsContainer.querySelector('a.fullSearch').classList.contains('inactive')) {
            this.searchResultsContainer.querySelector('a.fullSearch').focus();
        } else if (el == this.searchResultsContainer.querySelector('a.fullSearch')) {
            this.formInput.focus();
        }
    }

    /**
     * @param {HTMLElement} li 
     */
    arrowUp(el) {
        console.log(el);
        if (el.previousElementSibling && el.previousElementSibling.querySelector('a')) {
            el.previousElementSibling.querySelector('a').focus();
        } else if (el == this.formInput && !this.searchResultsContainer.querySelector('a.fullSearch').classList.contains('inactive')) {
            this.searchResultsContainer.querySelector('a.fullSearch').focus();
        } else if (el == this.searchResultsContainer.querySelector('a.fullSearch')) {
            this.searchResultsContainer.querySelector('ul li:last-of-type a').focus();
        } else {
            this.formInput.focus();
        }
    }
    searchResultsContainer 

    handleInputChange() {
        let query = this.formInput.value.trim();
        const clearButton = this.form.querySelector('button[type="reset"]');
        const focusButton = this.form.querySelector('button.focus');
        // const allResultsButton = this.formContainer.querySelector('searchResultsContainer');

        if (query && query.length >= this.minInput) {
            // Update status button to show
            this.updateResults(query);
            focusButton.removeAttribute('disabled');
            clearButton.removeAttribute('disabled');
        } 
        
        if (query) {
            // User entered less than 3 characters
            this.searchResultsContainer.querySelector('ul').innerHTML = '';
            this.searchResultsContainer.querySelector('ul').classList.remove('searchResults');
            this.originalSearchSuggestions.forEach(suggestion => this.searchResultsContainer.queruySelector('ul').appendChild(suggestion));
            this.searchResultsContainer.querySelector('.searchResultsContainerWrapper').classList.add('d-none');
            this.searchResultsContainer.querySelector('ul').classList.remove('loading');
            this.searchResultsContainer.querySelector('a.fullSearch').classList.add('inactive');
        } else {
            focusButton.setAttribute('disabled', 'disabled');
            clearButton.setAttribute('disabled', 'disabled');
            // allResultsButton.removeAttribute('disabled', 'disabled');
            this.resetResults();
        }
    }

    showSearchResults() {
        document.querySelector('.faqSearch').classList.add('.faqSearchActive');
        this.searchResultsContainer.classList.add('active');
        
        this.searchResultsContainer.setAttribute('aria-expanded', true);
        this.searchResultsContainer.setAttribute('aria-hidden', false);

        // Enable tabbing on all items
        this.searchResultsContainer.querySelectorAll('a, button, input').forEach(clickable => {
            clickable.setAttribute('tabindex', '0');
            clickable.setAttribute('aria-hidden', 'false');
        });
    }

    hideSearchResults() {
        document.querySelector('.faqSearch').classList.remove('.faqSearchActive');
        this.searchResultsContainer.classList.remove('active');

        this.searchResultsContainer.setAttribute('aria-expanded', false);
        this.searchResultsContainer.setAttribute('aria-hidden', true);

        setTimeout(() => {
            if (!this.searchResultsContainer.classList.contains('active')) {
                this.resetResults();
            }
        }, 400);

        // Disable tabbing on all items
        this.searchResultsContainer.querySelectorAll('a, button, input').forEach(clickable => {
            clickable.setAttribute('tabindex', '-1');
            clickable.setAttribute('aria-hidden', 'true');
        });
    }

    updateResults(query) {
        // Reset waiter to prevent unnessecary calls
        if (this.changeWaiter !== null) {
            clearTimeout(this.changeWaiter);
        }

        this.changeWaiter = setTimeout(() => {
            this.searchResultsContainer.querySelector('ul').classList.add('loading');

            // If there's a call going on, abort it and create a new abortcontroller to reuse
            if ('AbortController' in window && this.abortController) {
                this.abortController.abort();
                this.abortController = new AbortController();
            }

            const result = this.hasQuery(this.allSubjects, query);
            this.insertSearchSuggestions(result, query);
            this.searchResultsContainer.querySelector('ul').classList.remove('loading');
            this.searchResultsContainer.querySelector('.searchResultsContainerWrapper').classList.remove('d-none');
            this.changeWaiter = null;
        }, this.waiterTime);
    }

    insertSearchSuggestions(data, query = null) {
        // Data should be an array containing nothing but keywords. So create listitems that will search for that
        let suggestions = [];
        if (data) {
            data.forEach(item => {
                let title = item.title || item.highlightedTitle || item.url;
                title = title.replaceAll((new RegExp(query, 'ig')), `<strong>${query}</strong>`);

                let suggestion = new FaqSearchSuggestion({
                   url: item.url,
                   title: title,
                   category: item.category || ''
                });
    
                suggestions.push(suggestion.generate());
            });
        }        

        // Clear current suggestion list
        this.searchResultsContainer.querySelector('ul').innerHTML = '';

        // Add class
        this.searchResultsContainer.querySelector('ul').classList.add('searchResults');
        
        // Insert the newly made suggestions
        suggestions.forEach(suggestion => this.searchResultsContainer.querySelector('ul').appendChild(suggestion));

        // Hide title
        this.searchResultsContainer.querySelector('.searchResultsContainerWrapper').classList.add('d-none');

        // Update fullSearch button
        if (this.totalSubjects) {
            this.searchResultsContainer.querySelector('a.fullSearch').classList.remove('inactive');

            if (this.totalSubjects > 1) {
                this.searchResultsContainer.querySelector('a.fullSearch span').innerHTML = `Bekijk alle resultaten`;
            } else {
                this.searchResultsContainer.querySelector('a.fullSearch span').innerHTML = `Bekijk resultaat`;
            }
        } else {
            this.searchResultsContainer.querySelector('.fullSearch').classList.add('inactive');
            this.searchResultsContainer.querySelector('.searchResultsContainerWrapper').classList.remove('d-none');
        }

        // Update button text
        if (this.totalSubjects > 1) {
            this.searchResultsContainer.querySelector('a.fullSearch span').innerHTML = `Bekijk alle resultaten`;
            this.searchResultsContainer.querySelector('#searchResultsTitle').innerHTML = '';
        } else {
            this.searchResultsContainer.querySelector('a.fullSearch span').innerHTML = `Bekijk resultaat`;
            this.searchResultsContainer.querySelector('#searchResultsTitle').innerHTML = '';
        }

        // Update title
        if (this.totalSubjects < 1){
            this.searchResultsContainer.querySelector('#searchResultsTitle').innerHTML = 'Geen resultaten gevonden';
        }
        
    }

    resetResults() {
        // Cancel any ongoing call
        if ('AbortController' in window && this.abortController) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }

        // Clear input
        this.formInput.value = '';

        // Clear suggestion list
        this.searchResultsContainer.querySelector('ul').innerHTML = '';

        // Reset class
        this.searchResultsContainer.querySelector('ul').classList.remove('searchResults');
        
        // Insert backed up suggestions and title
        this.originalSearchSuggestions.forEach(suggestion => this.searchResultsContainer.querySelector('ul').appendChild(suggestion));
        this.searchResultsContainer.querySelector('.searchResultsContainerWrapper').classList.add('d-none');
        this.searchResultsContainer.querySelector('ul').classList.remove('loading');
        this.searchResultsContainer.querySelector('a.fullSearch').classList.add('inactive');
    }

    newPage() {
        this.form = null;
        this.formInput = null;
        this.searchResultsContainer = null;

        this.abortController = null; // Will contain an AbortController when making a new API call
        this.changeWaiter = null; // Clearable timeout handler

        this.originalSearchSuggestions = [];

        this.mark = null;

        if (document.querySelector('form.faqSearchForm')) {
            this.init();
        }
    }

    getData(){
        return fetch(this.apiEndpoint).then(response => {return response.json()}).then(data => {
            this.allSubjects = data;
            this.totalSubjects = this.allSubjects.length;
        }).finally(() => this.allSubjects)
    
    }

    hasQuery(data, query){        
        const allResults = data.filter((item) => {
            return item.title.toLowerCase().includes(query.toLowerCase());
        });
        this.totalSubjects = allResults.length;
        return allResults.slice(0, 5);
    }
}

