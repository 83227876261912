import CustomHTMLElement from './customHTMLElement';

export default class FaqSearchSuggestion extends CustomHTMLElement {
    /**
     * @param {Object} properties 
     * @param {String} properties.url
     * @param {String} properties.title
     * @param {String} properties.category
     */
	constructor(properties = {}) {
		super({
            url: '',
            title: '',
            category: '', 
		});
		this.setProperties(properties);
        this._createPropertyMethods();

		this._template = require('./templates/faqSearchSuggestion.handlebars');
    }
}