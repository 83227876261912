import Cookie from 'js-cookie';

export default class RibbonController {
    constructor() {
        this.container = null;
        this.resizeFunc = null;

        if (document.querySelector('.ribbonWrapper')) {
            this.container = document.querySelector('.ribbonWrapper');

            this.init();
        }
    }

    init() {
        // Decide wether it needs to be shown or not
        const id = this.container.dataset.id;
        const cookie = Cookie.get(`ribbon_${id}`);

        if (cookie === undefined) {
            this.setEventListener();
            this.show();
        }
    }

    setEventListener() {
        this.container.querySelector('.close').addEventListener('click', e => {
            e.preventDefault();
            this.hide();
        });
    }

    show() {
        this.container.classList.remove('d-none');
        void this.container.offsetWidth;

        const ribbon = this.container.querySelector('.ribbon');

        const resize = () => {
            let height = this.container.getBoundingClientRect().height || 0;
            document.documentElement.style.setProperty('--ribbonHeight', `${height}px`);
        }
        this.resizeFunc = window.app.registerResizeEvent(resize.bind(this));
        void this.container.offsetWidth;
        this.container.classList.add('animate');
        document.documentElement.classList.add('ribbonActive');

        resize();
        setTimeout(() => {
            this.container.classList.add('active');
            this.container.classList.remove('animate');
            resize();
        }, app.animationDuration);
    }

    hide() {
        const id = this.container.dataset.id;
        Cookie.set(`ribbon_${id}`, 1, {
            expires: 1,
        });

        this.container.classList.add('disable');
        document.documentElement.classList.remove('ribbonActive');
        document.documentElement.style.setProperty('--ribbonHeight', `0px`);
        ['transitionend', 'animationend'].forEach(event => {
            this.container.addEventListener(event, () => {
                this.container.remove();
            });
        });
        window.app.unregisterResizeEvent(this.resizeFunc);
    }
}